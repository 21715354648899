import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Zoom } from "react-awesome-reveal";

import Loading from "./loading";
import { Text } from "./language";

export class Header extends Component {
    render() {
        /*
        if (!this.props.data) {
            return <Loading />;
        }
        */

        return (
            <Container>
                <Row className="pb-2 align-items-center">
                    <Col xs={12} md={6}>
                        <Row className="justify-content-justify p-2">
                            <h1 style={{ textAlign: "center" }}>
                                <Text tid="Loncar.Title" html={true} />
                            </h1>
                            <br />
                            <br />
                            <Text tid="Loncar.Desc1" html={true} />
                            <br />
                            <br />
                            <Text tid="Loncar.Desc2" html={true} />
                            <br />
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row className="justify-content-center mr-2">
                            <Zoom triggerOnce>
                                <Image
                                    className="d-block mx-auto"
                                    alt="D365 Ecosystem"
                                    src="/img/D365-EcoSystem.png"
                                    style={{ width: "100%", height: "100%" }}
                                />
                            </Zoom>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Header;
