import React, { Component } from "react";
import { Card, Container, Row, Col, Image, Button } from "react-bootstrap";
import { Text } from "./language";

// technologies
import { technologies } from "../data/technologiesdb";

//import "./technologies.css";

export class Technologies extends Component {
    render() {
        return (
            <div>
                <Row xs={1} md={3}>
                    {technologies.categories.map((category, index) => (
                        <Col key={index}>
                            <Card className="focus mb-2">
                                <Card.Body>
                                    <Card.Title className="text-center  card-title">
                                        <Text tid={category.id} />
                                    </Card.Title>
                                    <hr />
                                    <Card.Text className="card-text d-flex justify-content-start flex-column">
                                        {category.items.map(
                                            (technology, index) => (
                                                <span key={index}>
                                                    <a
                                                        className={
                                                            technology.link !==
                                                            ""
                                                                ? "text-dark text-decoration-none"
                                                                : "disabled"
                                                        }
                                                        href={technology.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        aria-disabled={
                                                            technology.link !==
                                                            ""
                                                        }
                                                    >
                                                        <Image
                                                            src={
                                                                technology.imgSrc
                                                            }
                                                            alt={
                                                                technology.imgAltText
                                                            }
                                                            rounded
                                                            className="image-style m-1"
                                                        ></Image>{" "}
                                                        {
                                                            technology.technologyName
                                                        }
                                                    </a>
                                                </span>
                                            )
                                        )}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
}

export default Technologies;
