import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Rotate } from "react-awesome-reveal";

import Loading from "./loading";
import { Text } from "./language";

export class D365Modules extends Component {
    render() {
        /*
        if (!this.props.data) {
            return <Loading />;
        }
        */

        return (
            <Container>
                <Row className="pb-2 align-items-center">
                    <Col xs={12} md={6}>
                        <Row className="justify-content-center mr-2">
                            <Rotate triggerOnce>
                                <Image
                                    className="d-block mx-auto"
                                    alt="Dynamics 365 Screenshot"
                                    src="/img/Gears.png"
                                    style={{ width: "80%" }}
                                />
                            </Rotate>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row className="justify-content-start p-2">
                            <ol style={{ listStyle: "none" }}>
                                <li>
                                    <Text tid="Solutions.SCM" />
                                </li>
                                <ol style={{ listStyle: "none" }}>
                                    <li>
                                        <Text tid="Solutions.Procurement" />
                                    </li>
                                    <li>
                                        <Text tid="Solutions.Manufacturing" />
                                    </li>
                                    {/*
                                    <li>
                                        <Text tid="Solutions.Order" />
                                    </li>
                                    */}
                                    <li>
                                        <Text tid="Solutions.WHS" />
                                    </li>
                                    <ol style={{ listStyle: "none" }}>
                                        <li>
                                            <Text tid="Solutions.WHSApp" />
                                        </li>
                                    </ol>
                                    <li>
                                        <Text tid="Solutions.Inventory" />
                                    </li>
                                </ol>
                                <li>
                                    <Text tid="Solutions.Sales" />
                                </li>
                                <li>
                                    <Text tid="Solutions.Commerce" />
                                </li>
                                <ol style={{ listStyle: "none" }}>
                                    <li>
                                        <Text tid="Solutions.Retail" />
                                    </li>
                                    <li>
                                        <Text tid="Solutions.POS" />
                                    </li>
                                    <li>
                                        <Text tid="Solutions.eCommerce" />
                                    </li>
                                    {/*
                                    <li>
                                        <Text tid="Solutions.PaymentConnector" />
                                    </li>
                                    */}
                                </ol>
                                <li>
                                    <Text tid="Solutions.Project" />
                                </li>
                                <li>
                                    <Text tid="Solutions.Finance" />
                                </li>
                                <ol style={{ listStyle: "none" }}>
                                    <li>
                                        <Text tid="Solutions.GL" />
                                    </li>
                                    <li>
                                        <Text tid="Solutions.Credit" />
                                    </li>
                                    {/*
                                    <li>
                                        <Text tid="Solutions.Assets" />
                                    </li>
                                    */}
                                    <li>
                                        <Text tid="Solutions.Bank" />
                                    </li>
                                </ol>
                                <li>
                                    <Text tid="Solutions.MP" />
                                </li>
                            </ol>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default D365Modules;
