import React, { useContext, useEffect } from "react";
import { Cookies, getCookieConsentValue } from "react-cookie-consent";
import NavDropdown from "react-bootstrap/NavDropdown";

import { languageOptions } from "../languages";
import { LanguageContext } from "./language";

export default function LanguageSelector() {
    const { userLanguage, userLanguageChange } = useContext(LanguageContext);

    // set selected language by calling context method
    const handleLanguageChange = (eventKey) => userLanguageChange(eventKey);

    // useEffect to react after component was updated
    useEffect(() => {
        // Trying to get selected language or user's default language
        let defaultLanguage =
            (getCookieConsentValue() && Cookies.get("language")) ||
            (Window && Window.myLanguageCache) ||
            (navigator.languages && navigator.languages[0]) ||
            navigator.language ||
            navigator.userLanguage ||
            "en";

        userLanguageChange(defaultLanguage.substring(0, 2));
    }, [userLanguageChange]);

    return (
        <div className="languageselector">
            <NavDropdown
                title={
                    Object.entries(languageOptions).find(
                        ([id, name]) => id == userLanguage
                    )[1]
                }
                id="nav-dropdown"
                onSelect={handleLanguageChange}
            >
                {Object.entries(languageOptions).map(([id, name]) => (
                    <NavDropdown.Item key={id} eventKey={id}>
                        {name}
                    </NavDropdown.Item>
                ))}
            </NavDropdown>
        </div>
    );
}
