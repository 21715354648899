export const sitedata = {
    Site: {
        title: "Loncar Technologies Inc.",
        icon: "/Logo-182x55-trans.png",
        copyright: "©2023 Loncar Technologies Inc.",
    },
    Contact: {
        url: "https://loncargamesnodejs.azurewebsites.net/email/contact",
    },
    Privacy: {
        paragraph: "Privacy comes here",
    },
};
