import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";

import LanguageSelector from "./languageselector";
import { Text } from "./language";

export class Navigation extends Component {
    render() {
        return (
            <Navbar
                collapseOnSelect
                expand="lg"
                bg="primary"
                variant="light"
                sticky="top"
            >
                <Navbar.Brand href="#home" className="d-none d-lg-inline-block">
                    <img
                        className="logo"
                        src={this.props.data.icon}
                        alt="Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Brand
                    href="#home"
                    className="d-lg-none text-right"
                    style={{ display: "contents" }}
                >
                    <img
                        className="logo"
                        src={this.props.data.icon}
                        alt="Logo"
                    />
                </Navbar.Brand>
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="justify-content-end"
                >
                    <Nav>
                        <Nav.Link href="/#home" style={{ paddingRight: 22 }}>
                            <Text tid="Home" />
                        </Nav.Link>
                        <Nav.Link
                            href="/#d365modules"
                            style={{ paddingRight: 22 }}
                        >
                            <Text tid="Solutions" />
                        </Nav.Link>
                        {/*
                        <Nav.Link
                            href="/#technologies"
                            style={{ paddingRight: 22 }}
                        >
                            <Text tid="Technologies" />
                        </Nav.Link>
                        */}
                        <Nav.Link href="/#contact" style={{ paddingRight: 32 }}>
                            <Text tid="Contact" />
                        </Nav.Link>
                    </Nav>
                    <br />
                    <Nav style={{ paddingRight: 22 }}>
                        <LanguageSelector />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Navigation;
