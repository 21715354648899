import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Slide } from "react-awesome-reveal";

import Home from "./components/home";
import Contact from "./components/contact";
import Privacy from "./components/privacy";
import NotFound from "./components/notfound";
import Footer from "./components/footer";
import Loading from "./components/loading";
import Technologies from "./components/technologies";
import D365Modules from "./components/d365modules";

import { Text, LanguageContext } from "./components/language";

export class Routes extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        // Initial state
        this.state = { languageSet: false };
    }

    render() {
        if (!this.props.data) {
            return <Loading />;
        }

        // Called with queryString parameter?
        if (!this.state.languageSet) {
            this.state.languageSet = true;

            const searchParams = new URLSearchParams(document.location.search);

            const lang = searchParams.get("lang");

            if (lang !== null && lang != undefined && lang !== "") {
                const languageContext = this.context;

                if (lang == "en" || lang === "es" || lang === "fr") {
                    languageContext.userLanguageChange(lang);
                } else {
                    languageContext.userLanguageChange("en");
                }
            }
        }

        const sitedata = this.props.data;

        return (
            <Switch>
                <Route
                    exact
                    path={["/", "/index.php", "/index-es.php", "/index-fr.php"]}
                >
                    <hr id="home" />
                    <div>
                        {/*<div className="section-title">
                            <h2>
                                <Text tid="Home" />
                            </h2>
                        </div>*/}
                        {/*<Slide triggerOnce>*/}
                        <Home data={sitedata.Site} />
                        {/*</Slide>*/}
                    </div>
                    <hr id="d365modules" className="anchorHR" />
                    <div>
                        <div className="section-title">
                            <h2>
                                <Text tid="Solutions" />
                            </h2>
                        </div>
                        {/*<Slide triggerOnce>*/}
                        <D365Modules />
                        {/*</Slide>*/}
                    </div>
                    {/*
                    <hr id="technologies" className="anchorHR" />
                    <div>
                        <div className="section-title">
                            <h2>
                                <Text tid="Technologies" />
                            </h2>
                        </div>
                        <Slide triggerOnce direction="right">
                            <Technologies />
                        </Slide>
                    </div>
                    */}
                    <hr id="contact" className="anchorHR" />
                    <div>
                        <div className="section-title">
                            <h2>
                                <Text tid="Contact" />
                            </h2>
                        </div>
                        {/*<Slide triggerOnce direction="right">*/}
                        <Contact data={sitedata.Contact} />
                        {/*</Slide>*/}
                    </div>
                    <hr />
                    <Footer data={sitedata.Site} />
                </Route>
                <Route
                    exact
                    path={[
                        "/privacy",
                        "/privacy.php",
                        "/privacy-es.php",
                        "/privacy-fr.php",
                    ]}
                >
                    <Privacy data={sitedata.Privacy} />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        );
    }
}

export default Routes;
