import React, { Component } from "react";

import Loading from "./loading";
import Header from "./header";

export class Home extends Component {
    render() {
        if (!this.props.data) {
            return <Loading />;
        }

        return <Header data={this.props.data} />;
    }
}

export default Home;
