export const technologies = {
    categories: [
        {
            id: "Technology.Platforms",
            items: [
                {
                    link: "",
                    imgAltText: "Azure",
                    imgSrc: "/img/Azure.png",
                    technologyName: "Azure",
                },
                {
                    link: "",
                    imgAltText: "Power Platform",
                    imgSrc: "/img/PowerPlatform.png",
                    technologyName: "Power Platform",
                },
                {
                    link: "",
                    imgAltText: "Power Apps",
                    imgSrc: "/img/PowerApps.png",
                    technologyName: "Power Apps",
                },
                {
                    link: "",
                    imgAltText: "Power Automate",
                    imgSrc: "/img/PowerAutomate.png",
                    technologyName: "Power Automate",
                },
            ],
        },
        {
            id: "Technology.Databases",
            items: [
                {
                    link: "",
                    imgAltText: "SQL Server",
                    imgSrc: "/img/SQLServer.png",
                    technologyName: "SQL Server",
                },
            ],
        },
        {
            id: "Technology.Reporting",
            items: [
                {
                    link: "",
                    imgAltText: "Power BI",
                    imgSrc: "/img/PowerBI.png",
                    technologyName: "Power BI",
                },
                {
                    link: "",
                    imgAltText: "SSRS",
                    imgSrc: "/img/SSRS.png",
                    technologyName: "SSRS",
                },
                {
                    link: "",
                    imgAltText: "ER",
                    imgSrc: "/img/ER.png",
                    technologyName: "ER - Electronic reporting",
                },
            ],
        },
        {
            id: "Technology.Languages",
            items: [
                {
                    link: "",
                    imgAltText: "X++",
                    imgSrc: "/img/X++.png",
                    technologyName: "X++",
                },
                {
                    link: "",
                    imgAltText: "C#",
                    imgSrc: "/img/CSharp.png",
                    technologyName: "C#",
                },
                {
                    link: "",
                    imgAltText: "XML",
                    imgSrc: "/img/XML.png",
                    technologyName: "XML",
                },
                {
                    link: "",
                    imgAltText: "JSON",
                    imgSrc: "/img/JSON.png",
                    technologyName: "JSON",
                },
            ],
        },
        {
            id: "Technology.FrontEnd",
            items: [
                {
                    link: "",
                    imgAltText: "JavaScript",
                    imgSrc: "/img/JavaScript.png",
                    technologyName: "JavaScript",
                },
                {
                    link: "",
                    imgAltText: "React",
                    imgSrc: "/img/React.png",
                    technologyName: "React",
                },
                {
                    link: "",
                    imgAltText: "Bootstrap",
                    imgSrc: "/img/Bootstrap.png",
                    technologyName: "Bootstrap",
                },
                {
                    link: "",
                    imgAltText: "typeScript",
                    imgSrc: "/img/TypeScript.png",
                    technologyName: "TypeScript",
                },
            ],
        },
        {
            id: "Technology.Interfaces",
            items: [
                {
                    link: "",
                    imgAltText: "oData",
                    imgSrc: "/img/oData.png",
                    technologyName: "oData",
                },
                {
                    link: "",
                    imgAltText: "Web Services",
                    imgSrc: "/img/WebServices.png",
                    technologyName: "Web Services",
                },
            ],
        },
    ],
};
