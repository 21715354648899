import React, { Component } from "react";
import { Container } from "react-bootstrap";
import MetaTags from "react-meta-tags";

import Routes from "./routes";

import Navigation from "./components/navigation";
import CookieConsentExt from "./components/cookieconsentext";
import { LanguageProvider } from "./components/language";

import "./App.scss";

import { sitedata } from "./data/sitedata";

export class App extends Component {
    render() {
        const title = "Loncar Technologies";

        return (
            <div>
                <LanguageProvider>
                    <MetaTags>
                        <title>{title}</title>
                        <meta property="og:title" content={title} />
                    </MetaTags>
                    <div id="home">
                        <Container style={{ background: "#f2f2f2" }}>
                            <Navigation data={sitedata.Site} />
                            <Routes data={sitedata} />
                            <CookieConsentExt />
                        </Container>
                    </div>
                </LanguageProvider>
            </div>
        );
    }
}

export default App;
